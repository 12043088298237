.cssload-fond{
	position:relative;
	margin: auto;
}

.cssload-container-general
{
	animation:cssload-animball_two 1.15s infinite;
		-o-animation:cssload-animball_two 1.15s infinite;
		-ms-animation:cssload-animball_two 1.15s infinite;
		-webkit-animation:cssload-animball_two 1.15s infinite;
		-moz-animation:cssload-animball_two 1.15s infinite;
	width:43px; height:43px;
}
.cssload-internal
{
	width:43px; height:43px; position:absolute;
}
.cssload-ballcolor
{
	width: 19px;
	height: 19px;
	border-radius: 50%;
}
.cssload-ball_1, .cssload-ball_2, .cssload-ball_3, .cssload-ball_4
{
	position: absolute;
	animation:cssload-animball_one 1.15s infinite ease;
		-o-animation:cssload-animball_one 1.15s infinite ease;
		-ms-animation:cssload-animball_one 1.15s infinite ease;
		-webkit-animation:cssload-animball_one 1.15s infinite ease;
		-moz-animation:cssload-animball_one 1.15s infinite ease;
}
.cssload-ball_1
{
	background-color:rgb(203,32,37);
	top:0; left:0;
}
.cssload-ball_2
{
	background-color:rgb(248,179,52);
	top:0; left:23px;
}
.cssload-ball_3
{
	background-color:rgb(0,160,150);
	top:23px; left:0;
}
.cssload-ball_4
{
	background-color:rgb(151,191,13);
	top:23px; left:23px;
}





@keyframes cssload-animball_one
{
	0%{ position: absolute;}
	50%{top:12px; left:12px; position: absolute;opacity:0.5;}
	100%{ position: absolute;}
}

@-o-keyframes cssload-animball_one
{
	0%{ position: absolute;}
	50%{top:12px; left:12px; position: absolute;opacity:0.5;}
	100%{ position: absolute;}
}

@-ms-keyframes cssload-animball_one
{
	0%{ position: absolute;}
	50%{top:12px; left:12px; position: absolute;opacity:0.5;}
	100%{ position: absolute;}
}

@-webkit-keyframes cssload-animball_one
{
	0%{ position: absolute;}
	50%{top:12px; left:12px; position: absolute;opacity:0.5;}
	100%{ position: absolute;}
}

@-moz-keyframes cssload-animball_one
{
	0%{ position: absolute;}
	50%{top:12px; left:12px; position: absolute;opacity:0.5;}
	100%{ position: absolute;}
}

@keyframes cssload-animball_two
{
	0%{transform:rotate(0deg) scale(1);}
	50%{transform:rotate(360deg) scale(1.3);}
	100%{transform:rotate(720deg) scale(1);}
}

@-o-keyframes cssload-animball_two
{
	0%{-o-transform:rotate(0deg) scale(1);}
	50%{-o-transform:rotate(360deg) scale(1.3);}
	100%{-o-transform:rotate(720deg) scale(1);}
}

@-ms-keyframes cssload-animball_two
{
	0%{-ms-transform:rotate(0deg) scale(1);}
	50%{-ms-transform:rotate(360deg) scale(1.3);}
	100%{-ms-transform:rotate(720deg) scale(1);}
}

@-webkit-keyframes cssload-animball_two
{
	0%{-webkit-transform:rotate(0deg) scale(1);}
	50%{-webkit-transform:rotate(360deg) scale(1.3);}
	100%{-webkit-transform:rotate(720deg) scale(1);}
}

@-moz-keyframes cssload-animball_two
{
	0%{-moz-transform:rotate(0deg) scale(1);}
	50%{-moz-transform:rotate(360deg) scale(1.3);}
	100%{-moz-transform:rotate(720deg) scale(1);}
}






/******************Login css***********************/
.bw{
  background-color: #f0f;
}
.bw1{
  background-color: #ff0;
}
.mt50{
  margin-top: 50px;
}
.mt25{
  margin-top: 25px;
}
.mt30{
  margin-top: 30px;
}
.mt10{
  margin-top: 10px;
}
.mb25{
  margin-bottom: 25px;
}
.mb100{
  margin-bottom: 100px;
}

.astricsign{
  color: red;
  position: relative;
  left: 3px;
}
.LoginWrapper{
  background-image: url("../../../public/images/login.jpg");
  background-size: 100% 100%;
  text-align: center;
  vertical-align: middle !important;
}

.innloginwrap{
  text-align: center;
}
.labelCss{
  font-weight: normal !important;
}
.borderrightlogin{
    border-right: 1px solid #aaa;
}
.labelCss{
  font-weight: normal;
}

.logininput input{
  height: 35px;
}

#logInBtn{
  padding: 10px 30px;
  border-radius: 20px;
  background-color: #6d3c97;
/*  background-color: #76BC21;*/
  font-size: 15px;
  font-weight: bold;
  white-space:normal;
}

.loginforgotpass a{
  text-decoration: underline;
  color: #555;
  cursor: pointer;
  font-size: 12px;
}
.loginforgotpass a:hover{
  text-decoration: underline;
  color: var(--blue-color);
  cursor: pointer;
}

.loginlistpadd{
  padding-inline-start: 20px!important;
}
.showPwd{
  display: block;
}
.hidePwd{
  display: none;
}
.showPwd1{
  display: none;
}
.hidePwd1{
  display: block;
}
.showPwd2{
  display: block;
}
.hidePwd2{
  display: none;
}
.showPwd3{
  display: none;
}
.hidePwd3{
  display: block;
}
.showHideSignDiv {
  top: -25px;
  float: right;
  margin-right: 9px;
  z-index: 999;
  position: relative;
}
/******************signup css***********************/

#signUpBtn{
  padding: 10px 10px;
  border-radius: 20px;

}
.systemsecBtn{
  border-radius: 25px;
  padding: 10px 15px;
  background-color: var(--waikawagrey-color);  
  color: #fff !important;
}
.resendotbtn{
      padding: 10px 30px;
    border-radius: 20px;
    border-color:var(--blue-color);
    background: #fff;
    color: #333;
}
.loginBtn{
  border-radius: 25px;
  padding: 10px 15px;
  background-color: var(--blue-background);
  color: #fff !important;
}
.resetBtn{
  border-radius: 25px;
  padding: 10px 15px;
  background-color: var(--blue-color);  
  color: #fff;
}
.resetBtn:hover{
  color: #fff;
}
.checkUserExistsError{
  font-weight: 100;
    color:red;
    margin-top: 5px;
  }
  .passwordError{
    font-weight: 100;
      color:red;
      margin-top: 5px;
    }
  .logInBtn:hover{
    color: #fff !important;
  }
  .signupbtn:hover{
    color: #fff !important;

  }
  .formShadow{
    background: #fff;
    padding: 15px;
    display: inline-block !important;
    min-height: 300px !important  ;
    box-shadow: 2px 1px 7px 6px #33333326;
    float: unset;
    /*margin-top: 50px;*/
  }
  .logininput label{
    font-weight: 500;
  }
  .fa-spinner{
    font-size: 15px;
  }
  #logInBtn:focus{
    outline: none;
  }
  .sampleTable{
    margin-top: 3%
  }

  .bg-title{
    background-color: var(--blue-color);
    padding-top: 10px;
    padding-bottom: 12px;
    font-size: 24px;
    font-weight: bold;
  }
  .logo{
    width: 200px;
    margin-top: -50px;
  }
  .logoWrapper{
    position: absolute;
    top: 5px;
    left: 10px;
  }
  .periscopeLogo{
    width : 100%
  }
   .loginCase{
      background-color: #fff;
      height: 350px;
      box-shadow: 0px 0px 10px #666;
      border-radius: 3%;
      padding-top: 20px;
   }